// @property --rotate {
//     syntax: "<angle>";
//     initial-value: 132deg;
//     inherits: false;
// }
  
// :root {
//     --card-height: 65vh;
//     --card-width: calc(var(--card-height) / 1.5);
// }
  
  
.login-form {
    // min-height: 100vh;
    background: #ffffff;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    // height: 100vh;
}
.login-form-button {
  width: 100%;
}
  
  
// .card {
//     background: #FFF;
//     width: var(--card-width);
//     height: var(--card-height);
//     padding: 3px;
//     position: relative;
//     border-radius: 6px;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     display: flex;
//     font-size: 1.5em;
// }
  
// .card::before {
//     content: "";
//     width: 104%;
//     height: 102%;
//     border-radius: 8px;
//     background-image: linear-gradient(
//       var(--rotate)
//       , #5ddcff, #3c67e3 43%, #4e00c2);
//       position: absolute;
//       z-index: -1;
//       top: -1%;
//       left: -2%;
//       animation: spin 2.5s linear infinite;
// }
  
// .card::after {
//     position: absolute;
//     content: "";
//     top: calc(var(--card-height) / 6);
//     left: 0;
//     right: 0;
//     z-index: -1;
//     height: 100%;
//     width: 100%;
//     margin: 0 auto;
//     transform: scale(0.8);
//     filter: blur(calc(var(--card-height) / 6));
//     background-image: linear-gradient(
//       var(--rotate)
//       , #5ddcff, #3c67e3 43%, #4e00c2);
//       opacity: 1;
//     transition: opacity .5s;
//     animation: spin 2.5s linear infinite;
// }
  
// @keyframes spin {
//     0% {
//       --rotate: 0deg;
//     }
//     100% {
//       --rotate: 360deg;
//     }
// }
